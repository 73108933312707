import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import NextImage from '../../components/NextImage';
import { Title, Text as Paragraph } from '../../components/Typography';

export const Container = tw.div`
text-center
md:text-left
`;

Container.displayName = 'Container';

export const AssetPic = styled(NextImage as any)`
  width: 100%;
  height: auto;
`;
AssetPic.displayName = 'AssetPic';

export const SuperText = tw(Paragraph)`
  mb-2.5
`;
SuperText.displayName = 'SuperText';
export const TitleBig = tw(Title)`
mb-2.5
md:mb-5
`;
TitleBig.displayName = 'TitleBig';
export const Body = tw(Paragraph)`
  mb-5
  md:mb-30
`;
Body.displayName = 'Body';

export const ButtonContainer = tw.div`
inline-block`;
