import { MobileDownloadIcon } from '@icons/MobileDownloadIcon';
import { DictionaryService } from 'service/dictionary/dictionaryService';
import { Grid, GridItem, GridRow } from '../../components/Grid';
import { PlainButton } from '../../components/PlainButton';
import { ImagesService } from 'service/images/imagesService';

import { AssetPic, Body, ButtonContainer, Container, SuperText, TitleBig } from './styles';

import type { GenericDownloadComponent } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps, UseExtendedImage } from 'templates/TemplateMapper/types';

interface IGenericDownload extends ISharedTemplateProps {
  templateProps: UseExtendedImage<GenericDownloadComponent, 'assetComponent'>;
}

export const GenericDownload = ({
  templateProps: { downloadableFile, body, title, superText, assetComponent },
  recipeCopy,
}: IGenericDownload) => {
  const buttonLabel =
    DictionaryService.getCopy('download_button_label', recipeCopy ?? []) || 'Download';
  const mainContent = (
    <Container>
      <SuperText tag="div" type="lead">
        {superText}
      </SuperText>
      <TitleBig tag="div" type="xs">
        {title}
      </TitleBig>
      <Body tag="div" type="md">
        {body}
      </Body>
      <ButtonContainer>
        <form action={downloadableFile?.url} target="_blank">
          <PlainButton icon={<MobileDownloadIcon />} buttonType="submit" label={buttonLabel} />
        </form>
      </ButtonContainer>
    </Container>
  );
  return (
    <Grid>
      <GridRow columns={16}>
        {assetComponent?.asset?.[0]?.mobile ? (
          <>
            <GridItem colStart={4} colSpan={10} className="border-b border-heroCurve">
              <GridRow columns={10} className="pb-2.5 md:pb-5">
                <GridItem colStart={1} colSpan={4}>
                  <Container>
                    <AssetPic sources={ImagesService.getPictureSources(assetComponent.asset[0])} />
                  </Container>
                </GridItem>
                <GridItem colStart={5} colSpan={6}>
                  {mainContent}
                </GridItem>
              </GridRow>
            </GridItem>
          </>
        ) : (
          <>
            <GridItem colStart={4} colSpan={10} className="border-b border-heroCurve">
              <GridRow columns={10} className="pb-2.5 md:pb-5">
                <GridItem colStart={1} colSpan={10}>
                  {mainContent}
                </GridItem>
              </GridRow>
            </GridItem>
          </>
        )}
      </GridRow>
    </Grid>
  );
};
